import { Route, Switch } from 'react-router-dom'
import { Ranking } from './Ranking'
import { Layout } from '../layouts/Layout'

const Routes = () => (
  <Layout>
    <Switch>
      <Route
        path={'/'}
        exact
        component={Ranking}
      />
    </Switch>
  </Layout>
)

export default Routes
