import { createTheme } from '@mui/material'
import './index.css'

export const theme = createTheme({
  typography: {
    fontFamily: 'Arcadepi'
  },
})

export const BORDER_GRAY = '#9b97a0'
export const CONTAINER_GRAY = '#4f4c53'
export const INNER_CONTAINER_GRAY = '#2c2b2f'
export const DETAILS_GREEN = '#b4ff00'
export const SCROLLBAR_BACKGROUND = '#232225'
export const HIGHLIGHT_YELLOW = '#ffba00'
export const RETRO_BUTTON_BACKGROUND_GRAY = '#3d3d3d'
export const RETRO_BUTTON_INNER_SHADOW = '#1a1a1a'
export const RETRO_BUTTON_TEXT_GRAY = '#545454'
export const RETRO_BUTTON_HOVER_BACKGROUND_GRAY = '#7a787d'
