export const displayWallet = (wallet: string | undefined): string => {
  if (!wallet) return ''
  const firstChars = wallet.substring(0, 5)
  const lastChars = wallet.substring(wallet.length - 5, wallet.length)

  return `${firstChars}.....${lastChars}`
}

export const displayEth = (balance: string | undefined): string => {
  if (!balance) return ''
  const value = (+balance).toFixed(3)

  return `${value} ETH`
}

export const displayQuacks = (balance: string | undefined): string => {
  if (!balance) return ''
  const million = 1_000_000
  const value = (+balance)

  if (value <= million) return `${value} QKS`

  const formattedValue = (value / million).toFixed(2)
  return `${formattedValue}M QKS`
}
