import { combineReducers } from 'redux'
import loadingReducer from './loading.reducer'

// Add reducers here
const reducers = combineReducers({
  loading: loadingReducer,
})

const rootReducer = (state: any, action: any) => {
  if (action.type === 'LOGOUT') state = undefined
  return reducers(state, action)
}

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
