import axios from 'axios'
import { RankingUser } from '../types/types'

const get = async (): Promise<RankingUser[] | undefined> => {
  const url = 'https://decoupling-assets.nonconformistducks.com/ranking.json'
  const res = await axios.get(url, {
    withCredentials: false,
  })
  if (!res.data) return undefined

  return res.data
}

const RanakingService = {
  get
}

export default RanakingService
