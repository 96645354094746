/**
 * Developed by Skillful Dev LLC
 * Contact: contact@skillfuldev.com
 * website: https://skillfuldev.com
 * Copyright 2023
 */
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { QueryClient, QueryClientProvider } from 'react-query'
import { AppNavigation } from './components/navigation/navigation'
import { theme } from './styles/theme'

// eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
// window.Buffer = window.Buffer || require('buffer').Buffer

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 2 * (1000 * 60) // 2 min
    }
  }
})

const App = () => (
  <QueryClientProvider client={queryClient}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <AppNavigation />
      </ThemeProvider>
    </StyledEngineProvider>
  </QueryClientProvider>
)

export default App
