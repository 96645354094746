import { Container, CssBaseline } from '@mui/material'
import { Box } from '@mui/system'
import background from '../../assets/images/background.jpg'

interface Props {
  children: any
}

export const Layout = (props: Props) => (
  <Box sx={{
    display: 'flex',
    backgroundImage: `url(${background})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    '@media screen and (max-width:355px)': {
      backgroundRepeat: 'repeat',
      backgroundSize: 'cover',
    }
  }}>
    <CssBaseline />
    <Container maxWidth='xl'
      sx={{
        display: 'flex',
        height: '100vh',
        flexGrow: 1,
        overflowY: 'visible'
      }}>
      <Box
        component={'main'}
        sx={{
          marginTop: '5%',
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '3rem'

        }}
      >
        {props.children}
      </Box>
    </Container>
  </Box>
)
