import {
  Box,
  TextField,
  Tooltip,
  Typography,
  styled,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { Virtuoso } from 'react-virtuoso'
import {
  BORDER_GRAY,
  CONTAINER_GRAY,
  DETAILS_GREEN,
  INNER_CONTAINER_GRAY,
  SCROLLBAR_BACKGROUND
} from '../../../styles/theme'
import { RQueryKeys } from '../../../types/react-query'
import { RetroLoading } from '../../shared/RetroLoading'
import RanakingService from '../../../services/ranking.service'
import { RankingUser } from '../../../types/types'
import { displayWallet } from '../../../utils/wallet.helper'
import Logo from '../../../assets/images/257.png'

const optionsList = [
  { headerTitle: '#', description: null, hideOnPhone: false },
  { headerTitle: 'WALLET', description: null, hideOnPhone: false },
  { headerTitle: 'OG', description: 'OG ssets', hideOnPhone: true },
  { headerTitle: 'HELL', description: 'Hell assets', hideOnPhone: true },
  { headerTitle: 'UPSIDE', description: 'Upside assets', hideOnPhone: true },
  { headerTitle: '2GEN', description: '2gen assets', hideOnPhone: true },
  { headerTitle: 'ASSETS', description: 'Number of total assets', hideOnPhone: false },
  { headerTitle: 'LISTED', description: 'Number of assets that are listed on opensea', hideOnPhone: false },
  { headerTitle: 'POINTS', description: null, hideOnPhone: false },
]

const goOpenSea = (wallet: string) => {
  window.open(`https://opensea.io/${wallet}`)
}

const Scroller = React.forwardRef(({ style, ...props }: any, ref: any) => (
  <ListContainer style={{ ...style }} ref={ref} {...props} />))

export const Ranking = () => {
  const [search, setSearch] = useState<string>('')
  const [users, setUsers] = useState<RankingUser[]>([])
  const { data, isLoading } = useQuery(RQueryKeys.ranking, async () => {
    const res = await RanakingService.get()
    return res
  }, { staleTime: Infinity })

  useEffect(() => {
    if (users.length === 0 && data) setUsers([...data])
  }, [data])

  useEffect(() => {
    if (search === '' && data) setUsers(data)
    else if (data) {
      const filteredUsers = data.filter(
        (user) => user.wallet.toLowerCase().includes(search.toLowerCase())
          || user.ethNickname?.toLowerCase().includes(search.toLowerCase())
      )
      setUsers(filteredUsers)
    }
  }, [search])

  useEffect(() => {
    console.log(`users length ${users.length}`)
  }, [users])

  const typographyStyle = {
    fontSize: '0.8rem',
    color: 'white',
    '@media screen and (max-width:820px)': {
      fontSize: '0.6rem',
    },
  }

  const calculateStyle = (rankingUser: RankingUser) => {
    if (rankingUser.position === 1) return { bg: '#9ee200', winner: true }
    if (rankingUser.position > 1 && rankingUser.position <= 20) return { bg: '#2a2fc2', winner: true }
    return {}
  }

  const Item = ({ rankingUser }: { rankingUser: RankingUser }) => (
    <ListItem
      {...calculateStyle(rankingUser)}
      key={rankingUser.wallet}
    >
      <Cell>
        <Typography sx={typographyStyle}>{rankingUser.position}</Typography>
      </Cell>
      <Cell minWidth='6rem'>
        <Tooltip title={rankingUser.wallet} placement='top'>
          <Typography sx={{
            ...typographyStyle,
            '&:hover': {
              cursor: 'pointer'
            },
            '@media screen and (max-width:820px)': {
              fontSize: '0.7rem',
              maxWidth: '8rem',
            },
            '@media screen and (max-width:480px)': {
              fontSize: '0.55rem',
            },
          }} onClick={() => goOpenSea(rankingUser.wallet)}>
            {rankingUser.ethNickname ? rankingUser.ethNickname : displayWallet(rankingUser.wallet)}
          </Typography>
        </Tooltip>
      </Cell>
      <Cell hideOnPhone>
        <Typography sx={typographyStyle}>{rankingUser.assetsPerCollection.OG}</Typography>
      </Cell>
      <Cell hideOnPhone>
        <Typography sx={typographyStyle}>{rankingUser.assetsPerCollection.Hell}</Typography>
      </Cell>
      <Cell hideOnPhone>
        <Typography sx={typographyStyle}>{rankingUser.assetsPerCollection.Upside}</Typography>
      </Cell>
      <Cell hideOnPhone>
        <Typography sx={typographyStyle}>{rankingUser.assetsPerCollection['2 Gen']}</Typography>
      </Cell>
      <Cell>
        <Typography sx={typographyStyle}>{rankingUser.totalNfts}</Typography>
      </Cell>
      <Cell>
        <Typography sx={typographyStyle}>{rankingUser.assetsListed}</Typography>
      </Cell>
      <Cell>
        <Typography sx={{
          ...typographyStyle,
          fontWeight: 600,
          fontSize: '1rem',
          '@media screen and (max-width:820px)': {
            fontSize: '0.8rem',
          },
        }}>{rankingUser.points}</Typography>
      </Cell>
    </ListItem>
  )

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      marginTop: '5%',
      gap: '1rem',
      height: '90%',
      justifyContent: 'center',
      alignItems: 'center',
      width: '90%',
      '@media screen and (max-width:960px)': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
      },
      '@media screen and (min-width:625px) and (max-width:960px) and (min-height:550px)': {
        marginTop: '-20rem',
      },
      '@media screen and (min-width:625px) and (max-width:960px) and (min-height:480px)': {
        marginTop: '-15rem',
      },
    }}>
      <Typography
        sx={{
          color: 'white',
          '&:hover': {
            cursor: 'pointer'
          },
          position: 'fixed',
          top: '1rem',
          '@media screen and (max-width:960px)': {
            position: 'relative',
            top: '10rem'
          },
          '@media screen and (max-width:625px)': {
            position: 'relative',
            top: '-11rem'
          },
          '@media screen and (max-width:480px)': {
            position: 'relative',
            top: '-10rem'
          },
          '@media screen and (max-width:355px)': {
            position: 'relative',
            top: '-2rem'
          },
        }}
        onClick={() => window.open('https://nonconformistducks.com/')}
      >
        <Image src={Logo} />
      </Typography>
      <Typography sx={{
        position: 'fixed',
        color: 'white',
        display: 'flex',
        textAlign: 'center',
        fontSize: '1rem',
        width: '80%',
        top: '8rem',
        '@media only screen and (min-width:1500px)': {
          position: 'relative',
          top: '0rem',
        },
        '@media only screen and (max-width:960px)': {
          position: 'relative',
          top: '10rem',
          color: 'white',
        },
        '@media only screen and (max-width:625px)': {
          position: 'relative',
          fontSize: '.8rem',
          color: 'white',
          width: '90%',
          top: '-12rem',
        },
        '@media only screen and (max-width:480px)': {
          position: 'relative',
          top: '-11rem',
        },
        '@media only screen and (max-width:355px)': {
          position: 'relative',
          top: '-2rem',
        },
      }}>
        This is the ranking for Nonconformist Ducks collections.
        The ranking board gets updated everyday at 2:00AM
      </Typography>

      <BorderGray>
        <TextField
          fullWidth
          placeholder='search...'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          sx={{
            input: {
              color: 'white',
              fontSize: '1.5rem',
              borderRadius: '10px',
            },
            placeholder: {
              color: 'white',
              fontSize: '1.5rem',
            }
          }}
        />
      </BorderGray>

      <ListBorderGray>
        <InnerContainer>
          <RankingHeader>
            {
              optionsList.map((option) => (
                <Cell
                  minWidth={option.headerTitle === 'WALLET' ? '6rem' : undefined}
                  hideOnPhone={option.hideOnPhone}
                  key={option.headerTitle}
                >
                  {
                    option.description
                      ? <Tooltip title={option.description} placement='top'>
                        <Typography sx={{
                          color: 'white',
                          fontSize: '0.9rem',
                          '@media screen and (max-width:960px)': {
                            fontSize: '0.7rem',
                            color: 'white',
                          },
                          '@media screen and (max-width:820px)': {
                            fontSize: '0.5rem',
                            color: 'white',
                            marginLeft: '.3rem'
                          },
                          '@media screen and (max-width:355px)': {
                            marginLeft: '.5rem'
                          },
                        }}>{option.headerTitle}</Typography>
                      </Tooltip>
                      : <Typography sx={{
                        color: 'white',
                        fontSize: '0.9rem',
                        '@media screen and (max-width:960px)': {
                          fontSize: '0.7rem',
                          color: 'white',
                        },
                        '@media screen and (max-width:820px)': {
                          fontSize: '0.5rem',
                          color: 'white',
                          marginLeft: '.3rem'
                        },
                        '@media screen and (max-width:355px)': {
                          marginLeft: '.5rem'
                        },
                      }}>{option.headerTitle}</Typography>
                  }
                </Cell>
              ))
            }

          </RankingHeader>
          {/*         <ListContainer> */}
          {
            !data || isLoading || data.length === 0
              ? <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%'
              }}
              >
                <Box width={'50%'} height={'50%'}>
                  <RetroLoading />
                </Box>
              </Box>
              : <Virtuoso
                itemContent={(index, item) => <Item rankingUser={item} />}
                data={users}
                style={{
                  height: '100%',
                  width: '100%',
                }}
                components={{
                  Scroller: Scroller as any
                }}
              />
          }
          {/*           </ListContainer> */}
        </InnerContainer>
      </ListBorderGray>
    </Box>

  )
}

const ListBorderGray = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${BORDER_GRAY};
  border: solid black 3px;
  width: 100%;
  height:80%;
  border-radius: 1.5rem;
  padding: 0.5rem;

  @media screen and (max-width:960px){
    position: relative;
    top: 6rem;
    width: 90%;
    min-height: 20rem;
  }
  @media screen and (max-width:625px){
    position: relative;
    margin-top: -1rem;
    width: 100%;
    height:70vh;
  }
  @media screen and (max-width:480px){
    position: relative;
    width: 105%;
    height:70vh;
    margin-top: 0rem;
  }
  @media screen and (max-width:355px){
    width: 105%;
    height:70vh;
  }
  @media screen and (max-height:800px){
    width: 100%;
    height:60vh;
  }
  @media screen and (max-height:600px){
    width: 100%;
    height:53vh;
  }
`

const InnerContainer = styled('div')`
  display: flex;
  flex-direction: column;
  background-color: ${INNER_CONTAINER_GRAY};
  width: 100%;
  height: 100%;
  border-radius:  1.5rem;
  padding: 2rem 1rem 0.5rem 1rem;
`

const ListContainer = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  background-color: ${INNER_CONTAINER_GRAY};
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;

   ::-webkit-scrollbar {
    background-color: ${SCROLLBAR_BACKGROUND};
    border-radius: 50px;
    width: 10px;
  } 

  ::-webkit-scrollbar-button {
    height: 200px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${DETAILS_GREEN} ;
    border-radius: 50px;
  } 
`

const RankingHeader = styled('div')`
  background-color: ${CONTAINER_GRAY};
  width: 100%;
  height: 4rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 1rem;
  border-radius: 1rem;
  margin: 0 1rem 1rem 0;
  box-shadow: 10px 5px 5px black;
`

const ListItem = styled('div') <{ winner?: boolean, bg?: string }>`
  width: 100%;
  height: 3rem;
  background-color: ${(props) => (props.bg ? props.bg : CONTAINER_GRAY)};
  border-radius: 1rem;
  display: flex;
  justify-content: space-around;
  gap: 1rem;
  align-items: center;
  border: 1px soid red;
  margin-bottom: 1rem;

  @media screen and (max-height:800px){
    padding: .75rem;
  }

  @media screen and (max-height:600px){
    padding: .5rem;
  }
`

const Cell = styled('div') <{ minWidth?: string, hideOnPhone?: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content:center ;
  align-items:  center;
  min-width: ${(props) => (props.minWidth ? props.minWidth : '')};

  @media screen and (max-width: 625px) {
    display: ${(props) => (props.hideOnPhone ? 'none' : 'flex')};
  }
`
const Image = styled('img')`
  width: 200px;
`

const BorderGray = styled('div')`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: ${INNER_CONTAINER_GRAY};
  border-radius: 10px;
  border: ${BORDER_GRAY} 0.5rem solid;
  margin-top: 5rem;
  @media only screen and (min-width:1500px){
    margin-top: 2rem;
  }
  @media only screen and (max-width:960px){
    position: relative;
    top: 5rem;
    height: 3rem;
    width: 80%;
  }
  @media screen and (max-width:625px){
    width: 100%;
    height: 4rem;
    position: relative;
    margin-top: -17rem;
    display: flex;
    justify-content: center;
  }
  @media screen and (max-width:480px){
    width: 97%;
  }
  @media screen and (max-width:355px){
    width: 100%;
    margin-top: -7rem;
  }
`
