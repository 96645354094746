import { styled } from '@mui/material'
import LoadingGif from '../../assets/images/loading.gif'

export const RetroLoading = () => (
  <Image image={LoadingGif} />
)

const Image = styled('div') <{ image: string }>`
  width: 100%;
  height: 100%;
  background-image: ${(props) => (props.image ? `url(${props.image})` : '')} ;
  background-repeat: no-repeat ;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
`
